<template>
    <bread-pages>
        <div class="main" v-loading="loading">
            <div class="title">{{ info.name }}</div>
            <div class="content">
                <div class="sub-title">著录项</div>
                <div class="info">
                    <div class="info-item">
                        <span class="text1">申请号：</span>
                        <span class="text2">{{ info.code }}</span>
                    </div>
                    <div class="info-item" v-if="info.applyTime">
                        <span class="text1">申请日:</span>
                        <span class="text2">{{ info.applyTime }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">专利区分：</span>
                        <span class="text2">{{ info.type }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">行业分类：</span>
                        <span class="text2">{{ setting.name }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">法律状态：</span>
                        <span class="text2">{{ info.lawStatus }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">专利权人：</span>
                        <span class="text2">{{ info.owner }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">发明人：</span>
                        <span class="text2">{{ showList(info.inventor, ';') }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">公开号：</span>
                        <span class="text2">{{ info.openCode || '暂无' }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">分类号：</span>
                        <span class="text2">{{ showList(info.ipc, ';') }}</span>
                    </div>
                </div>
                <div class="sub-title">摘要</div>
                <div class="text" v-html="info.digest || '暂无内容'"></div>
                <div class="sub-title">权利要求书</div>
                <div class="text" v-html="info.manual || '暂无内容'"></div>
                <div class="sub-title">附图</div>
                <div class="text imgs">
                    <el-image :src="getImg(info.img)" fit="cover"></el-image>
                </div>
            </div>

            <!-- <div class="btn">
                <el-button type="primary" class="icon-button" size="small" plain>
                    <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                    咨询客服
                </el-button>
                <el-button type="primary" class="icon-button"  size="small" plain>
                    <i class="iconfont iconfont-nav_icon_xiaoxi"></i>提交留言
                </el-button>
            </div> -->
        </div>
    </bread-pages>
</template>

<script>
import BreadPage from '../../components/page/BreadPage.vue';
import BreadPages from '../../components/page/BreadPages.vue';
import {
    typeOptions,
    intePropLawStatus,
    ownerTypeOptions,
    tradingMethodOptions,
    commissionTypeOptions
} from '../../utils/variables';
export default {
    data() {
        return {
            info: {},
            typeOptions,
            intePropLawStatus,
            ownerTypeOptions,
            tradingMethodOptions,
            commissionTypeOptions,
            loading: true
        };
    },
    components: {
        BreadPage,
        BreadPages
    },
    computed: {
        setting() {
            return this.info.setting || {};
        }
    },
    mounted() {
        this.loading = true;
        this.$http.get('/intellectualProperty/get/' + this.$route.query.id).then(res => {
            this.info = res;
            setTimeout(() => {
                this.loading = false;
            }, 500);
            this.$EventBus.$emit('changePreTitle', {
                name: '知产',
                path: '/intellectualProperty'
            });
            this.$EventBus.$emit('changeTitle', '知产详情');
            document.title = '知产详情';
        });
    }
};
</script>

<style lang="less" scoped>
.main {
    background-color: #fff;
    padding: 0 30px 150px;
    position: relative;
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: @warn;
    line-height: 34px;
    text-align: center;
    padding: 50px 0 20px;
    border-bottom: 1px solid @bg;
    margin: 0 70px;
    border-bottom: 1px solid @bg;
}

.sub-title {
    font-size: 16px;
    font-weight: bold;
    color: @warn;
    line-height: 22px;
    padding: 50px 0 16px;
    .flex();
    &::before {
        content: '';
        width: 5px;
        height: 16px;
        background: @warn;
        border-radius: 3px;
        margin-right: 10px;
    }
}
.appContainer {
    background: #ffffff !important;
}
.info {
    .flex();
    flex-wrap: wrap;
    .info-item {
        width: 45%;
        padding-right: 50px;
        box-sizing: border-box;
        line-height: 32px;
        margin-bottom: 8px;
        .flex();

        .text1 {
            font-size: 14px;
            color: #999999;
            display: inline-block;
            min-width: 96px;
            flex-shrink: 0;
        }

        .text2 {
            font-size: 14px;
            color: #000;
            margin-left: 6px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:nth-child(2n) {
            .text1 {
                min-width: 98px;
            }
        }
    }
}

.text {
    font-size: 14px;
    color: #000000;
    line-height: 20px;
    &.imgs {
        .flex-col();
        align-items: center;
    }
}

.btn {
    .flex();
    justify-content: center;
    position: absolute;
    right: 60px;
    top: 120px;
    .icon-button {
        line-height: 32px;
    }
}
</style>
